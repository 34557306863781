/* eslint-disable @typescript-eslint/no-explicit-any */

import { observable, action, configure } from "mobx";
import { createContext, useContext } from "react";
import {
  ILobbyGetMessage,
  IMaterial,
  IVideo,
  OpenedModal,
  OpenedModalMsg,
  IGetRelationshipSettings,
  OpenedModalRelation,
  OpenedModalReminder,
  OpenedModalNotification,
  OpenedModalTestUser,
  OpenedModalTestAppointment,
  OpenedModalLanguage,
  IAppointmentList,
  ICardData,
  IFontFamily,
  IDefaultCardValue,
  TextTransform,
  ICustomCardValue,
} from "./GlobalStateType";
import {
  IGetReminderList,
  InewMaterial,
  ISystemSettings,
  IGetAppointmentMsg,
  IGetNotificationTemplate,
  IFetchTestUser,
  ITestProvider,
  ITestAppointmentList,
  IFetchLanguage,
  IThemeColors,
  ICustomLayoutType,
  IThemeDetails,
  IMeetingLog,
  statusLog,
} from "./StoreTypes";

configure({ enforceActions: "observed" });

export class GlobalStore {
  @observable patient = "";

  @observable notificationNumber = 0;

  @observable fullControlGroup = true;

  @observable openedModal: OpenedModal = "none";

  @observable openedModalMsg: OpenedModalMsg = "none";

  @observable openedModalRelation: OpenedModalRelation = "none";

  @observable openedModalLanguage: OpenedModalLanguage = "none";

  @observable openedModalTestUser: OpenedModalTestUser = "none";

  @observable openedModalTestAppointment: OpenedModalTestAppointment = "none";

  @observable openedModalNotification: OpenedModalNotification = "none";

  @observable openedModalReminder: OpenedModalReminder = "none";

  @observable actionMenuSelectedMaterial: IMaterial | null = null;

  @observable actionMenuSelectedVideo: IVideo | null = null;

  @observable actionMenuSelectedUserRole = false;

  @observable actionMenuSelectedLanguage: IFetchLanguage | null = null;

  @observable actionMenuSelectedTestUser: IFetchTestUser | null = null;

  @observable actionMenuSelectedTestUserObj: IFetchTestUser[] | null = null;

  @observable customScreenFlag = false;

  @observable actionMenuSelectedTestAppointmentObj:
    | ITestAppointmentList[]
    | null = null;

  @observable actionMenuSelectedTestAppointment: ITestAppointmentList | null =
    null;

  @observable actionMenuSelectedNotification: IGetNotificationTemplate | null =
    null;

  @observable actionMenuSelectedMessage: ILobbyGetMessage | null = null;

  @observable actionMenuSelectedAppointmentMsg: IGetAppointmentMsg | null =
    null;

  @observable actionMenuSelectedReminder: IGetReminderList | null = null;

  @observable actionMenuSelectedRelationship: IGetRelationshipSettings | null =
    null;

  @observable successMessage = "";

  @observable initialValue = false;

  @observable provider: ITestProvider | undefined;

  @observable newMaterial: InewMaterial = {
    title: "",
    description: "",
    url: "",
    fileName: "",
    uploadedBy: "",
    fileExtension: "",
    createdTime: "",
    modifiedDate: "",
    uniqueFileName: "",
    modifiedBy: "",
    thumbnailFileName: "",
    thumbnailUniqueFileName: "",
    thumbnailUrl: "",
    thumbnailExtension: "",
    isActive: "",
  };

  @observable globalNewMaterial: InewMaterial[] = [];

  @observable ErrorMsg = [
    {
      name: "Tiwari, Sneha",
      patientId: "FHM010741",
      provider: "Dr. Krystal McKinney",
      error: "Patient unable to start screen sharing",
    },
    {
      name: "Tiwari, Sneha",
      patientId: "FHM010741",
      provider: "Dr. Krystal McKinney",
      error: "James is facing issues with audio",
    },
  ];

  @observable loginUserEmail = "";

  @observable loginUserAadId = "";

  @observable selectedMaterialName = "";

  @observable selectedVideoName = "";

  @observable isDataAvailable = false;

  @observable openRecord = false;

  @observable currentCard = "All";

  @observable appointmentsList: IAppointmentList[] = [];

  @observable customFontsList: IDefaultCardValue[] = [];

  @observable cardData: ICardData[] = [];

  @observable isSummaryDetailsPage = false;

  @observable meetingDetailedView: IAppointmentList | null = null;

  @observable currentMeetingDetailedViewIndex: number | null = null;

  @observable currentMeetingListLength = 0;

  @observable customLayoutData: ICustomLayoutType = {
    layoutStyle: "",
    appTagLine: "",
    fileName: "",
    id: "",
    uniqueFileName: "",
    uploadedBy: "",
    uploadedTime: "",
    url: "",
  };

  @observable extendedBackgroundImage = this.customLayoutData?.url;

  @observable isCustomized;

  @observable imageName = this.customLayoutData?.fileName;

  @observable isCustomTheme = false;

  @observable tagLine = this.customLayoutData?.appTagLine;

  @observable customTabName = "Minimal layout";

  @observable fontFamilyTab: IFontFamily = {
    fontFamilyName: "",
    fontFamilyType: "",
  };

  @observable defaultCardValue: IDefaultCardValue[] = [
    {
      fontName: "Segoe UI",
      uploadedBy: "",
      fontType: "",
      isSelected: false,
      fontDetails: [
        {
          heading: "Heading 1",
          fontFamily: "Segoe UI",
          fontWeight: "400",
          lineHeight: "32px",
          fontSize: "28px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Heading 2",
          fontFamily: "Segoe UI",
          fontWeight: "600",
          lineHeight: "30px",
          fontSize: "22px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Heading 3",
          fontFamily: "Segoe UI",
          fontWeight: "600",
          lineHeight: "27px",
          fontSize: "20px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Body 1",
          fontFamily: "Segoe UI",
          fontWeight: "400",
          lineHeight: "30px",
          fontSize: "22px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Body 2",
          fontFamily: "Segoe UI",
          fontWeight: "400",
          lineHeight: "27px",
          fontSize: "20px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Body 3",
          fontFamily: "Segoe UI",
          fontWeight: "400",
          lineHeight: "24px",
          fontSize: "18px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Body 4",
          fontFamily: "Segoe UI",
          fontWeight: "400",
          lineHeight: "21px",
          fontSize: "16px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Body 5",
          fontFamily: "Segoe UI",
          fontWeight: "600",
          lineHeight: "21px",
          fontSize: "16px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Body 6",
          fontFamily: "Segoe UI",
          fontWeight: "400",
          lineHeight: "19px",
          fontSize: "14px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Body 7",
          fontFamily: "Segoe UI",
          fontWeight: "400",
          lineHeight: "16px",
          fontSize: "12px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Button",
          fontFamily: "Segoe UI",
          fontWeight: "600",
          lineHeight: "24px",
          fontSize: "18px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
        {
          heading: "Label",
          fontFamily: "Segoe UI",
          fontWeight: "600",
          lineHeight: "24px",
          fontSize: "18px",
          fontStyle: "normal",
          letterSpacing: "",
          textIndent: "",
          textTransform: "capitalize",
          textDecoration: "",
        },
      ],
    },
  ];

  @observable CustomCardValue: ICustomCardValue = {
    fontName: "Segoe UI",
    uploadedBy: "",
    fontType: "",
    isSelected: false,
    fontDetails: [
      {
        heading: "Heading 1",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "32px",
        fontSize: "28px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Heading 2",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "30px",
        fontSize: "22px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Heading 3",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "27px",
        fontSize: "20px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 1",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "30px",
        fontSize: "22px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 2",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "27px",
        fontSize: "20px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 3",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "24px",
        fontSize: "18px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 4",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "21px",
        fontSize: "16px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 5",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "21px",
        fontSize: "16px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 6",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "19px",
        fontSize: "14px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 7",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "16px",
        fontSize: "12px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Button",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "24px",
        fontSize: "18px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Label",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "24px",
        fontSize: "18px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
    ],
  };

  @observable CustomCardValueInitial: ICustomCardValue = {
    fontName: "Segoe UI",
    uploadedBy: "",
    fontType: "",
    isSelected: false,
    fontDetails: [
      {
        heading: "Heading 1",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "32px",
        fontSize: "28px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Heading 2",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "30px",
        fontSize: "22px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Heading 3",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "27px",
        fontSize: "20px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 1",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "30px",
        fontSize: "22px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 2",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "27px",
        fontSize: "20px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 3",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "24px",
        fontSize: "18px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 4",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "21px",
        fontSize: "16px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 5",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "21px",
        fontSize: "16px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 6",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "19px",
        fontSize: "14px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Body 7",
        fontFamily: "Segoe UI",
        fontWeight: "400",
        lineHeight: "16px",
        fontSize: "12px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Button",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "24px",
        fontSize: "18px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
      {
        heading: "Label",
        fontFamily: "Segoe UI",
        fontWeight: "600",
        lineHeight: "24px",
        fontSize: "18px",
        fontStyle: "normal",
        isPopupOpen: false,
        letterSpacing: "",
        textIndent: "",
        textTransform: "capitalize",
        textDecoration: "",
      },
    ],
  };

  @observable tabName: any = "Resources";

  @observable customLeftNav: any = "Theme";

  @observable showCustomSavePop = false;

  @observable showDefaultSavePop = false;

  @observable showLogoSavePop = false;

  @observable themeData: IThemeColors = {
    id: "",
    themeName: "",
    primaryColor: "",
    secondaryColor: "",
    pageBackgroundColor: "",
    subPageBackgroundColor: "",
    alertColor: "",
    headingsTextColor: "",
    bodyTextColor: "",
    themeType: "",
    createdBy: "",
    createdTime: "",
  };

  @observable defaultThemeData: IThemeColors = {
    id: "",
    themeName: "Oltiva Theme",
    primaryColor: "#4A8409",
    secondaryColor: "#0C2E70",
    pageBackgroundColor: "#FFFFFF",
    subPageBackgroundColor: "#F7F8FC",
    alertColor: "#CF2A2A",
    headingsTextColor: "#000000",
    bodyTextColor: "#8A8A8A",
    themeType: "Default",
    createdBy: "Publictester8",
    createdTime: "",
  };

  @observable customThemeData: IThemeDetails[] | null = null;

  @observable primaryColorHexValue = this.themeData.primaryColor;

  @observable secondaryColorHexValue = this.themeData.secondaryColor;

  @observable pageBackgroundColorHexValue = this.themeData.pageBackgroundColor;

  @observable subPageBackgroundColorHexValue =
    this.themeData.subPageBackgroundColor;

  @observable alertColorHexValue = this.themeData.alertColor;

  @observable headingColorHexValue = this.themeData.headingsTextColor;

  @observable bodyColorHexValue = this.themeData.bodyTextColor;

  @observable layoutType = this.customLayoutData?.layoutStyle;

  @observable globalAppointmentsList: IAppointmentList[] = [];

  @observable themeCardValues = [
    {
      name: "Tiwar, Sneha",
      status: "In Progress",
      ApptText: "Appointment Date and Time",
      Date: "03/06/2021 2:45 PM",
      ProviderText: "Provider",
      Provider: "Dr. Krystal McKinney",
      Join: "Join Visit",
      End: "End Visit",
    },
    {
      name: "Tiwar, Sneha",
      status: "Completed",
      ApptText: "Appointment Date and Time",
      Date: "03/06/2021 2:45 PM",
      ProviderText: "Provider",
      Provider: "Dr. Krystal McKinney",
      Join: "",
      End: "",
    },
    {
      name: "Tiwar, Sneha",
      status: "Scheduled",
      ApptText: "Appointment Date and Time",
      Date: "03/06/2021 2:45 PM",
      ProviderText: "Provider",
      Provider: "Dr. Krystal McKinney",
      Join: "Join Visit",
      End: "",
    },
    {
      name: "Tiwar, Sneha",
      status: "Waiting",
      ApptText: "Appointment Date and Time",
      Date: "03/06/2021 2:45 PM",
      ProviderText: "Provider",
      Provider: "Dr. Krystal McKinney",
      Join: "Join Visit",
      End: "End Visit",
    },
    {
      name: "Tiwar, Sneha",
      status: "Delayed",
      ApptText: "Appointment Date and Time",
      Date: "03/06/2021 2:45 PM",
      ProviderText: "Provider",
      Provider: "Dr. Krystal McKinney",
      Join: "Join Visit",
      End: "End Visit",
    },
  ];

  @observable themeStatusTabs = [
    {
      name: "All",
      count: 5,
      className: "active",
    },
    {
      name: "In Progress",
      count: 1,
      className: "",
    },
    {
      name: "Waiting",
      count: 1,
      className: "",
    },
    {
      name: "Delayed",
      count: 1,
      className: "",
    },
    {
      name: "Scheduled",
      count: 1,
      className: "",
    },
    {
      name: "Completed",
      count: 1,
      className: "",
    },
  ];

  @observable themeName = "";

  @observable stepDetailsNew: IMeetingLog = {
    emailStatus: null,
    meetingLink: null,
    loginDetails: null,
    deviceSettings: null,
    lobbyPage: null,
    callStatus: null,
    exitStatus: null,
  };

  @observable isCreateTheme = false;

  @observable isShowUpdatePopup = false;

  @observable selectedThemeData: IThemeDetails | null = null;

  @observable similarTheme = false;

  @observable selectedThemeTab = "Default Theme";

  @action setSelectedThemeTab = (value: string) => {
    this.selectedThemeTab = value;
  };

  @action setSimilarTheme = (value: boolean) => {
    this.similarTheme = value;
  };

  @action setIsCreateTheme = (isCreateTheme: boolean) => {
    this.isCreateTheme = isCreateTheme;
  };

  @observable currentCustomScreen: any = "customFontSelection"; // it has three values customFontSelection, fontFamilyDropdown, customFontCreation

  @observable selectedFont = "";

  @action setCustomFontsList = (value: IDefaultCardValue[]) => {
    this.customFontsList = value;
  };

  @action setSelectedFont = (value: string) => {
    this.selectedFont = value;
  };

  @action setMeetingLogsDetails = (stepDetailsNew: IMeetingLog) => {
    this.stepDetailsNew = stepDetailsNew;
  };

  @action setCurrentCustomScreen = (value: string) => {
    this.currentCustomScreen = value;
  };

  @action setThemeName = (value: string) => {
    this.themeName = value;
  };

  @action setCustomThemeData = (value: IThemeDetails[] | null) => {
    this.customThemeData = value;
  };

  @action setSelectedThemeData = (value: IThemeDetails | null) => {
    this.selectedThemeData = value;
  };

  @action setCustomLayoutData = (value: ICustomLayoutType) => {
    this.customLayoutData = value;
  };

  @action setIsCustomTheme = (isCustomTheme: boolean) => {
    this.isCustomTheme = isCustomTheme;
  };

  @action setThemeData = (themeData: IThemeColors) => {
    this.themeData = themeData;
  };

  @action deleteAppointmentList = (appointmentId: string) => {
    this.globalAppointmentsList = this.globalAppointmentsList.filter(
      (value: IAppointmentList) => value.televisitId !== appointmentId
    );
    this.appointmentsList = this.appointmentsList.filter(
      (value: IAppointmentList) => value.televisitId !== appointmentId
    );
  };

  @action updateAppointmentList = (
    appointmentId: string,
    appointment: IAppointmentList
  ) => {
    const globalAppointmentsListIndex = this.globalAppointmentsList.findIndex(
      (x) => x.televisitId === appointmentId
    );
    const appointmentsListIndex = this.appointmentsList.findIndex(
      (x) => x.televisitId === appointmentId
    );
    this.globalAppointmentsList[globalAppointmentsListIndex] = appointment;
    this.appointmentsList[appointmentsListIndex] = appointment;
  };

  @action insertAppointmentList = (appointment: IAppointmentList) => {
    this.globalAppointmentsList.push(appointment);

    this.appointmentsList.push(appointment);
  };

  @action getDefaultFontDetails = (defaultFontVal: IDefaultCardValue[]) => {
    this.defaultCardValue = defaultFontVal;
  };

  @action setSelectedFontSize = (fontSize: string, headingName: string) => {
    const temp = { ...this.CustomCardValue };
    temp.fontDetails.map((item) => {
      /* eslint-disable no-param-reassign */
      if (item.heading === headingName) {
        item.fontSize = fontSize;
      }
      return item;
    });

    this.CustomCardValue = temp;
  };

  @action setSelectedFontWeight = (
    fontWeight: string,
    fontStyle: string,
    headingName: string
  ) => {
    const temp = { ...this.CustomCardValue };
    temp.fontDetails.map((item) => {
      /* eslint-disable no-param-reassign */
      if (item.heading === headingName) {
        item.fontWeight = fontWeight;
        item.fontStyle = fontStyle;
      }
      return item;
    });

    this.CustomCardValue = temp;
  };

  @action setSearchText = (val: string, headingName: string, type: string) => {
    const temp = { ...this.CustomCardValue };
    temp.fontDetails.map((item) => {
      /* eslint-disable no-param-reassign */
      if (item.heading === headingName) {
        if (type === "lineHeight") {
          item.lineHeight = val;
        }
        if (type === "letterSpacing") {
          item.letterSpacing = val;
        }
        if (type === "textIndent") {
          item.textIndent = val;
        }
      }
      return item;
    });

    this.CustomCardValue = temp;
  };

  @action setTextTransform = (
    textTransform: TextTransform,
    headingName: string,
    type: string
  ) => {
    const temp = { ...this.CustomCardValue };
    temp.fontDetails.map((item) => {
      /* eslint-disable no-param-reassign */
      if (item.heading === headingName) {
        if (type === "textTransform") {
          item.textTransform = textTransform;
          item.textDecoration = "";
        }
      }
      return item;
    });

    this.CustomCardValue = temp;
  };

  @action setTextDecoration = (
    textDecoration: string,
    headingName: string,
    type: string
  ) => {
    const temp = { ...this.CustomCardValue };
    temp.fontDetails.map((item) => {
      /* eslint-disable no-param-reassign */
      if (item.heading === headingName) {
        if (type === "textDecoration") {
          item.textDecoration = textDecoration;
          item.textTransform = "none";
        }
      }
      return item;
    });

    this.CustomCardValue = temp;
  };

  @action setShowCustomSavePop = (showCustomSavePop: boolean) => {
    this.showCustomSavePop = showCustomSavePop;
  };

  @action setShowDefaultSavePop = (showDefaultSavePop: boolean) => {
    this.showDefaultSavePop = showDefaultSavePop;
  };

  @action setShowLogoSavePop = (showLogoSavePop: boolean) => {
    this.showLogoSavePop = showLogoSavePop;
  };

  @action cancelCustomFontsPopup = () => {
    this.CustomCardValue = JSON.parse(
      JSON.stringify(this.CustomCardValueInitial)
    );
  };

  @action saveCustomFontsPopup = (newCardVal: ICustomCardValue) => {
    this.CustomCardValue = JSON.parse(JSON.stringify(newCardVal));
    this.CustomCardValueInitial = JSON.parse(JSON.stringify(newCardVal));
  };

  @action setTabName = (tabName: any) => {
    this.tabName = tabName;
  };

  @action setCustomLeftNav = (customLeftNav: any) => {
    this.customLeftNav = customLeftNav;
  };

  @action setCustomTabName = (customTabName: any) => {
    this.customTabName = customTabName;
  };

  @action setTagLine = (tagLine: any) => {
    this.tagLine = tagLine;
  };

  @action setIsCustomized = (isCustomized: any) => {
    this.isCustomized = isCustomized;
  };

  @action setUpdatePopup = (val: boolean) => {
    this.isShowUpdatePopup = val;
  };

  @action addToDefaultFonts = (newCardVal: IDefaultCardValue) => {
    const temp = this.defaultCardValue;
    temp.push(newCardVal);
    this.defaultCardValue = temp;
  };

  @action setFontFamilyTab = (fontFamily: string, FontType: string) => {
    this.fontFamilyTab = {
      fontFamilyName: fontFamily,
      fontFamilyType: FontType,
    };
  };

  @action setCustomFonCreationVal = (val: boolean, jsonData: any) => {
    if (val === true) {
      this.CustomCardValue = {
        fontName: this.fontFamilyTab.fontFamilyName,
        uploadedBy: "",
        fontType: "",
        isSelected: false,
        fontDetails: [
          {
            heading: "Heading 1",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "32px",
            fontSize: "28px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Heading 2",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "30px",
            fontSize: "22px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Heading 3",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "27px",
            fontSize: "20px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 1",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "30px",
            fontSize: "22px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 2",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "27px",
            fontSize: "20px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 3",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "24px",
            fontSize: "18px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 4",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "21px",
            fontSize: "16px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 5",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "21px",
            fontSize: "16px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 6",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "19px",
            fontSize: "14px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 7",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "16px",
            fontSize: "12px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Button",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "24px",
            fontSize: "18px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Label",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "24px",
            fontSize: "18px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
        ],
      };
      this.CustomCardValueInitial = {
        fontName: this.fontFamilyTab.fontFamilyName,
        uploadedBy: "",
        fontType: "",
        isSelected: false,
        fontDetails: [
          {
            heading: "Heading 1",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "32px",
            fontSize: "28px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Heading 2",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "30px",
            fontSize: "22px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Heading 3",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "27px",
            fontSize: "20px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 1",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "30px",
            fontSize: "22px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 2",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "27px",
            fontSize: "20px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 3",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "24px",
            fontSize: "18px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 4",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "21px",
            fontSize: "16px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 5",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "21px",
            fontSize: "16px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 6",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "19px",
            fontSize: "14px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Body 7",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "400",
            lineHeight: "16px",
            fontSize: "12px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Button",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "24px",
            fontSize: "18px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
          {
            heading: "Label",
            fontFamily: this.fontFamilyTab.fontFamilyName,
            fontWeight: "600",
            lineHeight: "24px",
            fontSize: "18px",
            fontStyle: "normal",
            isPopupOpen: false,
            letterSpacing: "",
            textIndent: "",
            textTransform: "capitalize",
            textDecoration: "",
          },
        ],
      };
    } else {
      this.CustomCardValue = JSON.parse(JSON.stringify(jsonData));
      this.CustomCardValueInitial = JSON.parse(JSON.stringify(jsonData));
    }
  };

  @action setUpdateFontVal = (item) => {
    this.CustomCardValue = JSON.parse(JSON.stringify(item));
    this.CustomCardValueInitial = JSON.parse(JSON.stringify(item));
  };

  @action setCardData = (cardData: ICardData[]) => {
    this.cardData = cardData;
  };

  @action setImageName = (imageName: string) => {
    this.imageName = imageName;
  };

  @action setExtendedBackgroundImage = (extendedBackgroundImage: any) => {
    this.extendedBackgroundImage = extendedBackgroundImage;
  };

  @action setLayoutType = (layoutType: string) => {
    this.layoutType = layoutType;
  };

  @action setPrimaryColorHexValue = (primaryColorHexValue: string) => {
    this.primaryColorHexValue = primaryColorHexValue;
  };

  @action setSecondaryColorHexValue = (secondaryColorHexValue: string) => {
    this.secondaryColorHexValue = secondaryColorHexValue;
  };

  @action setPageBackgroundColorHexValue = (
    pageBackgroundColorHexValue: string
  ) => {
    this.pageBackgroundColorHexValue = pageBackgroundColorHexValue;
  };

  @action setSubPageBackgroundColorHexValue = (
    subPageBackgroundColorHexValue: string
  ) => {
    this.subPageBackgroundColorHexValue = subPageBackgroundColorHexValue;
  };

  @action setAlertColorHexValue = (alertColorHexValue: string) => {
    this.alertColorHexValue = alertColorHexValue;
  };

  @action setHeadingColorHexValue = (headingColorHexValue: string) => {
    this.headingColorHexValue = headingColorHexValue;
  };

  @action setBodyColorHexValue = (bodyColorHexValue: string) => {
    this.bodyColorHexValue = bodyColorHexValue;
  };

  @action setMeetingDetailedView = (
    appointments: IAppointmentList[],
    index: number | null,
    currentLength: number
  ) => {
    this.meetingDetailedView = index !== null ? appointments[index] : null;
    this.currentMeetingDetailedViewIndex = index;
    this.currentMeetingListLength = currentLength;
  };

  @action setSummaryDetailsPage = (isSummaryDetailsPage: boolean) => {
    this.isSummaryDetailsPage = isSummaryDetailsPage;
  };

  @action setOpenRecord = (openRec: boolean) => {
    this.openRecord = openRec;
  };

  @action setCustomScreenFlag = (flag: boolean) => {
    this.customScreenFlag = flag;
  };

  @action setAppointmentsList = (appointmentsList: IAppointmentList[]) => {
    this.appointmentsList = appointmentsList;
  };

  @action setGlobalAppointmentsList = (
    appointmentsList: IAppointmentList[]
  ) => {
    this.globalAppointmentsList = appointmentsList;
  };

  @action setSelectedMaterialName = (name: string) => {
    this.selectedMaterialName = name;
  };

  @action setSelectedVideoName = (name: string) => {
    this.selectedVideoName = name;
  };

  @action setCurrentCard = (name: string) => {
    this.currentCard = name;
  };

  @observable selectedSystemSetting: ISystemSettings | null = null;

  @action setDataAvailable = (val: boolean) => {
    this.isDataAvailable = val;
  };

  @action setSelectedSystemSetting = (settings: ISystemSettings | null) => {
    this.selectedSystemSetting = settings;
  };

  @action setSystemSettingsDuration = (value: string) => {
    if (this.selectedSystemSetting) {
      this.selectedSystemSetting = {
        ...this.selectedSystemSetting,
        time: value,
      };
    }
  };

  @action setPatient = (val: string) => {
    this.patient = val;
  };

  @action setGlobalMaterialList = (val: InewMaterial) => {
    this.newMaterial = { ...val };
  };

  @action setOpenedModal = (modalOpened: OpenedModal) => {
    this.openedModal = modalOpened;
  };

  @action setOpenedModalNotification = (
    modalOpened: OpenedModalNotification
  ) => {
    this.openedModalNotification = modalOpened;
  };

  @action setOpenedModalMsg = (modalOpenedMsg: OpenedModalMsg) => {
    this.openedModalMsg = modalOpenedMsg;
  };

  @action setOpenedModalTestUser = (
    modalOpenedTestUser: OpenedModalTestUser
  ) => {
    this.openedModalTestUser = modalOpenedTestUser;
  };

  @action setProvider = (provider: ITestProvider) => {
    this.provider = provider;
  };

  @action setOpenedModalTestAppointment = (
    value: OpenedModalTestAppointment
  ) => {
    this.openedModalTestAppointment = value;
  };

  @action setOpenedModalRelation = (
    modalOpenedRelation: OpenedModalRelation
  ) => {
    this.openedModalRelation = modalOpenedRelation;
  };

  @action setOpenedModalLanguage = (
    modalOpenedLanguage: OpenedModalLanguage
  ) => {
    this.openedModalLanguage = modalOpenedLanguage;
  };

  @action setOpenedModalReminder = (
    modalOpenedReminder: OpenedModalReminder
  ) => {
    this.openedModalReminder = modalOpenedReminder;
  };

  @action setActionMenuSelectedMaterial = (material: IMaterial | null) => {
    this.actionMenuSelectedMaterial = material;
  };

  @action setActionMenuSelectedVideo = (video: IVideo | null) => {
    this.actionMenuSelectedVideo = video;
  };

  @action setActionMenuSelectedLanguage = (value: IFetchLanguage | null) => {
    this.actionMenuSelectedLanguage = value;
  };

  @action setActionMenuSelectedMessage = (message: ILobbyGetMessage | null) => {
    this.actionMenuSelectedMessage = message;
  };

  @action setActionMenuSelectedTestAppointment = (
    value: ITestAppointmentList | null
  ) => {
    this.actionMenuSelectedTestAppointment = value;
  };

  @action setActionMenuSelectedTestUser = (value: IFetchTestUser | null) => {
    this.actionMenuSelectedTestUser = value;
  };

  @action setActionMenuSelectedTestUserObj = (
    value: IFetchTestUser[] | null
  ) => {
    this.actionMenuSelectedTestUserObj = value;
  };

  @action setActionMenuSelectedTestAppointmentObj = (
    value: ITestAppointmentList[] | null
  ) => {
    this.actionMenuSelectedTestAppointmentObj = value;
  };

  @action setActionMenuSelectedUserRole = (value: boolean) => {
    this.actionMenuSelectedUserRole = value;
  };

  @action setActionMenuSelectedNotification = (
    notify: IGetNotificationTemplate | null
  ) => {
    this.actionMenuSelectedNotification = notify;
  };

  @action setNotificationCounts = (notificationLength: number) => {
    this.notificationNumber = notificationLength;
  };

  @action setLoginUserEmail = (loginEmail: string) => {
    this.loginUserEmail = loginEmail;
  };

  @action setLoginUserAadId = (aadId: string) => {
    this.loginUserAadId = aadId;
  };

  @action setActionMenuSelectedAppointmentMsg = (
    message: IGetAppointmentMsg | null
  ) => {
    this.actionMenuSelectedAppointmentMsg = message;
  };

  @action setActionMenuSelectedRelationship = (
    relationship: IGetRelationshipSettings | null
  ) => {
    this.actionMenuSelectedRelationship = relationship;
  };

  @action setActionMenuSelectedReminder = (
    reminder: IGetReminderList | null
  ) => {
    this.actionMenuSelectedReminder = reminder;
  };

  @action setSuccessMessage = (message: string) => {
    this.successMessage = message;
  };

  @action setInitialValue = () => {
    this.initialValue = !this.initialValue;
  };
}
export const StoreContext = createContext<GlobalStore>({} as GlobalStore);
export const GlobalStoreProvider = StoreContext.Provider;
export const useGlobalStore = (): GlobalStore => useContext(StoreContext);
