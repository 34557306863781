/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import HeaderLogo from "../../assets/oltiva_logo.svg";
import Profile from "../../assets/profile_pic.png";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import NotificationIcon from "../../assets/notification_icon.svg";

export default function StandardLayout() {
  const globalStore = useGlobalStore();
  const pageName = "MinimalLayout";
  const appInsights = useAppInsightsContext();
  const [isPreview, setIsPreview] = useState(false);
  const [tagLine, setTagLine] = useState(globalStore.tagLine);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isValid, setIsValid] = useState<any>("");
  const helperAdd = helperMethods();

  useEffect(() => {
    trackComponent();
  }, []);

  const cancelLayout = () => {
    globalStore.setLayoutType(globalStore.customLayoutData.layoutStyle);
    globalStore.setExtendedBackgroundImage(globalStore.customLayoutData.url);
    globalStore.setTagLine(globalStore.customLayoutData.appTagLine);
  };

  const applyLayout = () => {
    globalStore.setIsCustomized(false);
    const formData = new FormData();
    tagLine !== ""
      ? (setIsValid("valid"),
        formData.append("layoutStyle", globalStore.layoutType),
        formData.append("appTagLine", tagLine),
        formData.append("backgroundImage", ""),
        formData.append(
          "uploadedBy",
          "publictester3@acp200021a.onmicrosoft.com"
        ),
        setShowSpinner(true),
        apiService
          .addCustomLayout(formData)
          .then((res: any) => {
            if (res === "success") {
              // helperAdd.setSuccessMessageEvent("Successfully Updated");
              setShowSpinner(false);
              globalStore.setIsCustomized(true);
            }
          })
          .catch((error: any) => {
            globalStore.setIsCustomized(false);
            setShowSpinner(false);
            AppInsightMethods.TrackAppInsightsException(
              `Error atadd layout API API call, Component: ${pageName}, Error: ${error}`
            );
          }))
      : (setIsValid("invalid"), globalStore.setIsCustomized(false));
  };

  return useObserver(() => {
    return (
      <>
        <div className="tagContainer">
          <p className="tagLine">Application Tag Line</p>
          <TextField
            error={isValid === "invalid"}
            helperText={isValid === "invalid" ? "Enter TagLine" : ""}
            className="textBox"
            value={tagLine}
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => {
              setTagLine(e.target.value);
            }}
          />
        </div>
        <Grid
          className="previewText"
          style={isPreview ? { color: "#8A8A8A" } : { color: "#1447D4" }}
          onClick={() => {
            setIsPreview(true);
          }}
        >
          Preview
        </Grid>
        {isPreview && (
          <div className="layoutTypeContainer" style={{ height: "80px" }}>
            <img src={HeaderLogo} alt="logo" className="logo" />
            <div style={tagLine !== "" ? { alignSelf: "center" } : {}}>
              <p className="headerName">OLTIVA HEALTHCARE</p>
              <p className="tagLineText">{tagLine}</p>
            </div>
            <Grid className="headerRight">
              <img
                src={NotificationIcon}
                alt="Notification"
                className="notificationIcon"
              />
              <img src={Profile} alt="Profile" className="profile" />
              <p className="adminText">Admin</p>
              <KeyboardArrowDownOutlinedIcon className="arrowDown" />
            </Grid>
          </div>
        )}
        <div
          className="buttons"
          style={isPreview ? { marginTop: "32px" } : { marginTop: "170px" }}
        >
          <Button className="cancelButton" onClick={cancelLayout}>
            Cancel
          </Button>
          <Button className="saveButton" onClick={applyLayout}>
            Save
          </Button>
        </div>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
