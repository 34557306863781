/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useGlobalStore } from "../../state/GlobalStore";

export const SnackBar = () => {
  const globalStore = useGlobalStore();

  const handleClick = () => {
    globalStore.setIsCustomized(false);
    globalStore.setSimilarTheme(false);
  };

  return useObserver(() => {
    setInterval(function () {
      globalStore.setIsCustomized(false);
      globalStore.setSimilarTheme(false);
    }, 5000);
    return (
      <>
        {globalStore.isCustomized === true && (
          <div className="SnackBar">
            <CheckCircleOutlineIcon className="icon" />
            <p style={{ fontWeight: "600", marginRight: "5px" }}>
              {globalStore.customTabName}
            </p>
            <p> saved successfully</p>
            <CloseIcon className="close" onClick={handleClick} />
          </div>
        )}
        {globalStore.similarTheme === true && (
          <div
            className="SnackBar"
            style={{ backgroundColor: "#ffd6d6", color: "#EC5959" }}
          >
            <CheckCircleOutlineIcon className="icon" />
            <p style={{ fontWeight: "600", marginRight: "5px" }}>
              Theme Already Exists
            </p>
            {/* <p> saved successfully</p> */}
            <CloseIcon className="close" onClick={handleClick} />
          </div>
        )}
      </>
    );
  });
};
