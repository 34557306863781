/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Button from "@material-ui/core/Button";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import HeaderLogo from "../../assets/oltiva_logo.svg";
import Profile from "../../assets/profile_pic.png";
import NotificationIcon from "../../assets/notification_icon.svg";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";

export default function CompactLayout() {
  const globalStore = useGlobalStore();
  const pageName = "Layout";
  const appInsights = useAppInsightsContext();
  const [isPreview, setIsPreview] = useState(false);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const helperAdd = helperMethods();

  useEffect(() => {
    trackComponent();
  }, []);

  const cancelLayout = () => {
    globalStore.setLayoutType(globalStore.customLayoutData.layoutStyle);
    globalStore.setExtendedBackgroundImage(globalStore.customLayoutData.url);
    globalStore.setTagLine(globalStore.customLayoutData.appTagLine);
  };

  const applyLayout = () => {
    globalStore.setIsCustomized(false);
    const formData = new FormData();
    formData.append("layoutStyle", globalStore.layoutType);
    formData.append("appTagLine", "");
    formData.append("backgroundImage", "");
    formData.append("uploadedBy", "publictester3@acp200021a.onmicrosoft.com");
    setShowSpinner(true);
    apiService
      .addCustomLayout(formData)
      .then((res: any) => {
        if (res === "success") {
          setShowSpinner(false);
          globalStore.setIsCustomized(true);
        }
        // props.closeCallback();
        // globalStore.setOpenedModal("none");
        // props.callBackGetMaterialList();
        // setUpload(false);
      })
      .catch((error: any) => {
        setShowSpinner(false);
        globalStore.setIsCustomized(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at add layout API API call, Component: ${pageName}, Error: ${error}`
        );
      });
  };

  return useObserver(() => {
    return (
      <>
        <Grid
          className="previewText"
          style={isPreview ? { color: "#8A8A8A" } : { color: "#1447D4" }}
          onClick={() => {
            setIsPreview(true);
          }}
        >
          Preview
        </Grid>
        {isPreview && (
          <div className="layoutTypeContainer" style={{ height: "80px" }}>
            <img src={HeaderLogo} alt="logo" className="logo" />
            <p className="headerName">OLTIVA HEALTHCARE</p>
            <Grid className="headerRight">
              <img
                src={NotificationIcon}
                alt="Notification"
                className="notificationIcon"
              />
              <img src={Profile} alt="Profile" className="profile" />
              <p className="adminText">Admin</p>
              <KeyboardArrowDownOutlinedIcon className="arrowDown" />
            </Grid>
          </div>
        )}
        <div
          className="buttons"
          style={isPreview ? { marginTop: "32px" } : { marginTop: "170px" }}
        >
          <Button className="cancelButton" onClick={cancelLayout}>
            Cancel
          </Button>
          <Button className="saveButton" onClick={applyLayout}>
            Save
          </Button>
        </div>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
