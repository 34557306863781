/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import CustomLeftNav from "./CustomLeftNav";
import CustomContainerContent from "./CustomContainerContent";
import { SnackBar } from "./SnackBar";
import { apiService } from "../../services/ApiService";
import { ICustomLayoutType, IThemeColors } from "../../state/StoreTypes";

interface IHeadingProps {
  title: string;
}

const Heading = (props: IHeadingProps) => {
  const globalStore = useGlobalStore();

  const handleSettingsClick = () => {
    if (props.title === "Create Theme") {
      globalStore.setIsCreateTheme(false);
      globalStore.setCustomLeftNav("Theme");
      globalStore.setSelectedThemeTab("Custom Theme");
    } else {
      globalStore.setCustomScreenFlag(false);
      globalStore.setCustomLeftNav("Theme");
      globalStore.setTabName("Resources");
    }
  };

  return useObserver(() => {
    return (
      <>
        {(globalStore.isCustomized || globalStore.similarTheme) && <SnackBar />}
        <Grid className={"customContainerContent-heading"}>
          <ArrowBackIosNewIcon
            className="backArrow"
            onClick={handleSettingsClick}
          />
          {props.title}
        </Grid>
      </>
    );
  });
};

export default function CustomContainer() {
  const appInsights = useAppInsightsContext();
  const pageName = "CustomContainer";
  const globalStore = useGlobalStore();
  const [tabName, setTabName] = useState("Theme");
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightsConstants.Information,
      `${pageName} Page Load`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  }, []);

  useEffect(() => {
    apiService
      .getCustomLayout()
      .then((resData: any) => {
        if (resData) {
          const LayoutData: ICustomLayoutType = {
            layoutStyle: resData.layoutStyle,
            appTagLine: resData.appTagLine,
            fileName: resData.fileName,
            id: resData.id,
            uniqueFileName: resData.uniqueFileName,
            uploadedBy: resData.uploadedBy,
            uploadedTime: resData.uploadedTime,
            url: resData.url,
          };
          globalStore.setCustomLayoutData(LayoutData);
          globalStore.setLayoutType(LayoutData.layoutStyle);
          globalStore.setExtendedBackgroundImage(LayoutData.url);
          globalStore.setTagLine(LayoutData.appTagLine);
        }
      })
      .catch((error: any) => {
        AppInsightMethods.TrackAppInsightsException(
          `Error at Get layout API call, Component: ${pageName}, Error: ${error}`
        );
      });
  }, []);

  return useObserver(() => {
    return (
      <>
        {/* <CustomLeftNav ontabChangeEvent={globalStore.setCustomLeftNav} /> */}
        <Grid className={"custom_Container"}>
          {globalStore.showCustomSavePop && (
            <Grid className="custonFontSavePopup">
              <CheckCircleOutlineIcon className="checkIcon" />
              <span className="ctmFont">
                {globalStore.fontFamilyTab.fontFamilyName}{" "}
              </span>{" "}
              <span className="svd"> font has been applied successfully.</span>
              <ClearIcon
                className="crossIcon"
                onClick={() => globalStore.setShowCustomSavePop(false)}
              />
            </Grid>
          )}

          {globalStore.showDefaultSavePop && (
            <Grid className="custonFontSavePopup">
              <CheckCircleOutlineIcon className="checkIcon" />
              <span className="svd">
                {" "}
                The system font has been applied successfully.
              </span>
              <ClearIcon
                className="crossIcon"
                onClick={() => globalStore.setShowDefaultSavePop(false)}
              />
            </Grid>
          )}
          {globalStore.showLogoSavePop && (
            <Grid className="custonFontSavePopup">
              <CheckCircleOutlineIcon className="checkIcon" />
              <span className="ctmFont">Logo</span>{" "}
              <span className="svd"> saved successfully.</span>
              <ClearIcon
                className="crossIcon"
                onClick={() => globalStore.setShowLogoSavePop(false)}
              />
            </Grid>
          )}
          <Heading title={globalStore.customLeftNav} />
          <CustomContainerContent tabName={globalStore.customLeftNav} />
        </Grid>
      </>
    );
  });
}
