/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightsConstants } from "../../Constants";
import { AppInsightMethods } from "../../AppInsightMethods";
import SearchIcon from "../../assets/search_icon.svg";
import CalendarIcon from "../../assets/calendar_icon.svg";
import FilterIcon from "../../assets/filter_icon.svg";
import ListIcon from "../../assets/list_icon.svg";
import ThemeIcon from "../../assets/theme.svg";
import OltivaIcon from "../../assets/oltiva_logo.svg";
import DeleteIcon from "../../assets/delete_icon.svg";
import DeleteActiveIcon from "../../assets/delete_icon_active.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: -30,
      marginLeft: -50,
      width: 493,
      height: 261,
    },
    paper: {
      margin: "auto",
      maxWidth: 500,
      width: 493,
      height: 300,
      // marginTop: 265,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    cardPaper: {
      height: 80,
      width: 80,
      marginBottom: 8,
    },
    cardGrid: {
      marginTop: 30,
      marginLeft: 103,
      width: 400,
      height: 217,
      position: "absolute",
      // left: "19%",
      // top: "32.5%",
    },
  })
);

interface IColors {
  primaryColor: string;
  secondaryCOlor: string;
  pageBgColor: string;
  subPageBgColor: string;
  alertColor: string;
  headingColor: string;
  bodyColor: string;
  themeName: string;
}

export default function DefaultThemeContainer(props: IColors) {
  const globalStore = useGlobalStore();
  const pageName = "DefaultThemeContainer";
  const appInsights = useAppInsightsContext();
  const [searchText, setSearchText] = useState("");
  const trackComponent = useTrackMetric(appInsights, pageName);
  const classes = useStyles();

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <>
        <Grid className="theme-Container">
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Grid
                className="themeContainer-header"
                style={{ backgroundColor: `${props.primaryColor}` }}
              >
                <img
                  className="oltivaIcon"
                  src={OltivaIcon}
                  alt="oltiva_icon"
                />
                OLTIVA HEALTHCARE
              </Grid>
              <Grid
                className="themeContainer-appointment"
                style={{
                  backgroundColor: `${props.subPageBgColor}`,
                }}
              >
                <p
                  className="heading"
                  style={{ color: `${props.headingColor}` }}
                >
                  Appointments
                </p>
                <Button
                  className="button"
                  style={{
                    backgroundColor: `${props.secondaryCOlor}`,
                  }}
                >
                  New Appointment
                </Button>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  className="themeContainer-sideBar"
                  style={{
                    backgroundColor: `${props.subPageBgColor}`,
                  }}
                >
                  {/* <p className="heading">Appointments</p> */}
                  <div>
                    <img
                      className="searchIcon"
                      src={SearchIcon}
                      alt="search_icon"
                    />
                    <p
                      className="addProviderText"
                      style={{ color: `${props.bodyColor}` }}
                    >
                      Add Provider
                    </p>
                    <AddIcon className="addIcon" />
                  </div>
                  <div className="providerList">
                    <p style={{ color: `${props.bodyColor}` }}>My Providers</p>
                    <p
                      className="active"
                      style={{
                        color: `${props.bodyColor}`,
                        backgroundColor: `${props.alertColor}`,
                      }}
                    >
                      Dr. Krystal McKinney
                    </p>
                    <p style={{ color: `${props.bodyColor}` }}>
                      Dr. Paul Johnson
                    </p>
                    <p style={{ color: `${props.bodyColor}` }}>
                      Dr. Melina Malik
                    </p>
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm container> */}
                <div className="schedule">
                  <p
                    className="text"
                    style={{ color: `${props.headingColor}` }}
                  >
                    Today's Schedule
                  </p>
                  <img src={CalendarIcon} alt="" />
                  <p className="day" style={{ color: `${props.headingColor}` }}>
                    Today
                  </p>
                  <ArrowBackIosNewIcon className="backIcon" />
                  <ArrowForwardIosIcon className="frontIcon" />
                  <p
                    className="date"
                    style={{ color: `${props.headingColor}` }}
                  >
                    03 June 2021
                  </p>
                </div>
                <div className="statusTabs">
                  {globalStore.themeStatusTabs?.map((tabs) => (
                    <p
                      className={`tabs ${tabs.className}`}
                      style={{ color: `${props.secondaryCOlor}` }}
                    >
                      {tabs.name}({tabs.count})
                    </p>
                  ))}
                  <div
                    className="searchBox"
                    style={{
                      backgroundColor: `${props.alertColor}`,
                    }}
                  >
                    <img
                      className="searchIcon"
                      src={SearchIcon}
                      alt="search_icon"
                    />
                    <p style={{ color: `${props.bodyColor}` }}>Search</p>
                  </div>
                  <div className="filter">
                    <img
                      className="filterIcon"
                      src={FilterIcon}
                      alt="filter_icon"
                    />
                    <p style={{ color: `${props.bodyColor}` }}>Filter</p>
                  </div>
                  <div className="icons">
                    <img className="listIcon" src={ListIcon} alt="list_icon" />
                    <img className="cardIcon" src={ThemeIcon} alt="card_icon" />
                  </div>
                </div>
                <Grid
                  container
                  className={classes.cardGrid}
                  spacing={2}
                  style={{
                    backgroundColor: `${props.pageBgColor}`,
                  }}
                >
                  {globalStore.themeCardValues?.map((value) => (
                    <Grid className="cardPaperGrid" item>
                      <Paper className="cardPaper">
                        <div className="name">{value.name}</div>
                        <div
                          className="statusColor"
                          style={
                            value.status === "In Progress"
                              ? { backgroundColor: "#E9F7E5" }
                              : value.status === "Waiting"
                              ? { backgroundColor: "#F9F9E6" }
                              : value.status === "Delayed"
                              ? { backgroundColor: "#F9E9E8" }
                              : value.status === "Scheduled"
                              ? { backgroundColor: "#EFEFEF" }
                              : { backgroundColor: "#A8A8A8" }
                          }
                        >
                          <p
                            className="status"
                            style={
                              value.status === "In Progress"
                                ? { color: "#18760A" }
                                : value.status === "Waiting"
                                ? { color: "#70590D" }
                                : value.status === "Delayed"
                                ? { color: "#972424" }
                                : value.status === "Scheduled"
                                ? { color: "#979797" }
                                : { color: "#FFFFFF" }
                            }
                          >
                            {value.status}
                          </p>
                        </div>
                        <div className="appointment">
                          <p className="apptText">{value.ApptText}</p>
                          <p className="date">{value.Date}</p>
                        </div>
                        <div className="providerDetails">
                          <p className="providerText">{value.ProviderText}</p>
                          <p className="provider">{value.Provider}</p>
                        </div>
                        {/* <div className="buttons">
              <p className="join">{value.Join}</p>
              <p className="end">{value.End}</p>
            </div> */}
                      </Paper>
                    </Grid>
                  ))}
                  {/* </Grid> */}
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
        <div className="buttons">
          <EditIcon className="edit" />
          <img className="delete" src={DeleteIcon} alt="delete_icon" />
        </div>
        <div className="nameContainer">
          <p className="themeName">{props.themeName}</p>
        </div>
      </>
    );
  });
}
