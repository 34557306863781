/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Grid, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useObserver } from "mobx-react-lite";
import {
  useTrackMetric,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import cancel from "../../assets/cancel.png";
import update from "../../assets/update.svg";
import { useGlobalStore } from "../../state/GlobalStore";
import { AppInsightsConstants } from "../../Constants";
import { AppInsightMethods } from "../../AppInsightMethods";

export default function UpdateFont() {
  const pageName = "UpdateFont";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  const onOkbtnClick = () => {
    try {
      globalStore.setCurrentCustomScreen("customFontCreation");
      globalStore.setUpdatePopup(false);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightsConstants.Information,
        `Font section update button click event.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onUpdatebtnClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <>
        <Dialog
          open={globalStore.isShowUpdatePopup}
          onClose={() => globalStore.setUpdatePopup(false)}
        >
          <Grid className="deleteDialog">
            <DialogTitle className="titleDelete">
              <b>Update</b>
              <IconButton
                onClick={() => {
                  globalStore.setUpdatePopup(false);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <DialogContentText className="contentText">
                <span className={"textBold"}>
                  {globalStore.fontFamilyTab.fontFamilyName}{" "}
                </span>
                font already exists in custom fonts. Do you want to update it? ?
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialogbtnStyle">
              <Button
                onClick={() => {
                  globalStore.setUpdatePopup(false);
                }}
                className="btnStyle"
              >
                <img
                  src={cancel}
                  alt="cancel"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
              <Button style={{ paddingRight: "2rem" }} onClick={onOkbtnClick}>
                <img
                  src={update}
                  alt="ok"
                  className="logo pointer cursor"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                />
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
      </>
    );
  });
}
