/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  CardActionArea,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { observer, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import { useGlobalStore } from "../../state/GlobalStore";
import { apiService } from "../../services/ApiService";
import { AppInsightMethods } from "../../AppInsightMethods";

const DefaultFonts = () => {
  const globalStore = useGlobalStore();
  const pageName = "DefaultFonts";
  const appInsights = useAppInsightsContext();
  const [showSpinner, setShowSpinner] = useState(false);
  const [value, setFontValue] = useState("");

  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
    setShowSpinner(true);
    apiService
      .getSelectedFontDetails()
      .then((resData: any) => {
        setShowSpinner(false);
        if (resData) {
          globalStore.setSelectedFont(resData.fontName);
          if (resData.fontName === "Segoe UI") {
            setFontValue("Segoe UI");
          }
        }
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at get fontdetails API call, Component: ${pageName}, Error: ${error}`
        );
      });
  }, []);

  const handleFontCancel = () => {
    if (globalStore.selectedFont === "Segoe UI") {
      setFontValue("Segoe UI");
    } else {
      setFontValue("");
    }
  };

  const handleFontSave = () => {
    setShowSpinner(true);

    globalStore.defaultCardValue[0].fontType = "System";
    globalStore.defaultCardValue[0].isSelected = true;
    globalStore.defaultCardValue[0].uploadedBy = globalStore.loginUserEmail;

    apiService
      .addFontDetailsApi(globalStore.defaultCardValue[0])
      .then((res: any) => {
        setShowSpinner(false);
        globalStore.setShowDefaultSavePop(true);
        globalStore.setSelectedFont(res.fontName);
        setTimeout(() => {
          globalStore.setShowDefaultSavePop(false);
        }, 5000);
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at add fontdetails API call, Component: ${pageName}, Error: ${error}`
        );
      });
  };

  const getDropdownValue = (fontWeightVal: string, fontStyleVal: string) => {
    if (fontWeightVal === "300" && fontStyleVal === "normal") {
      return "Light";
    }
    if (fontWeightVal === "400" && fontStyleVal === "normal") {
      return "Regular";
    }
    if (fontWeightVal === "350" && fontStyleVal === "normal") {
      return "Semilight";
    }
    if (fontWeightVal === "600" && fontStyleVal === "normal") {
      return "Semibold";
    }
    if (fontWeightVal === "700" && fontStyleVal === "normal") {
      return "Bold";
    }
    if (fontWeightVal === "900" && fontStyleVal === "normal") {
      return "Black";
    }
    if (fontWeightVal === "400" && fontStyleVal === "Italic") {
      return "Italic";
    }
    if (fontWeightVal === "600" && fontStyleVal === "Italic") {
      return "Semibold Italic";
    }
    if (fontWeightVal === "700" && fontStyleVal === "Italic") {
      return "Bold Italic";
    }
    if (fontWeightVal === "350" && fontStyleVal === "Italic") {
      return "Semilight Italic";
    }
    return "Light";
  };

  const handleFontSelection = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if ((event.target as HTMLInputElement).value !== value) {
      setFontValue((event.target as HTMLInputElement).value);
    } else {
      setFontValue("");
    }
  };

  return useObserver(() => {
    return (
      <Grid className="defaultFontGrid">
        <div className="cardMain">
          <div
            style={{
              marginTop: "42px",
              color: "#8A8A8A",
              font: "normal normal normal 18px/24px Segoe UI",
            }}
          >
            Select Font :
          </div>

          <div style={{ width: "96%", marginTop: "20px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FormControl
                  key={globalStore.defaultCardValue[0].fontName}
                  className="frmCtr"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="radioGrp"
                    value={value}
                  >
                    <FormControlLabel
                      className="frmCtrLbl"
                      value={globalStore.defaultCardValue[0].fontName}
                      control={
                        <Radio
                          onClick={(event) => handleFontSelection(event)}
                        />
                      }
                      label={globalStore.defaultCardValue[0].fontName}
                    />
                  </RadioGroup>
                </FormControl>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "30px",
                  }}
                >
                  {globalStore.defaultCardValue[0].fontDetails.map(
                    (subItem) => (
                      <Card
                        sx={{ minWidth: 275, width: 340 }}
                        className="cardDefaultFont"
                        key={
                          globalStore.defaultCardValue[0].fontName +
                          subItem.heading
                        }
                      >
                        <CardContent className="cardContentDefaultFont">
                          <Typography
                            className="Heading"
                            style={{
                              font: `${subItem.fontStyle} ${subItem.fontWeight} ${subItem.fontSize}/${subItem.lineHeight} ${subItem.fontFamily}`,
                              textIndent: subItem.textIndent,
                              letterSpacing: subItem.letterSpacing,
                              textTransform: subItem?.textTransform,
                              textDecoration: subItem.textDecoration,
                            }}
                          >
                            {subItem.heading}
                          </Typography>
                          <Typography className="fontSizeWeight">
                            {subItem.fontFamily},{" "}
                            {getDropdownValue(
                              subItem.fontWeight,
                              subItem.fontStyle
                            )}
                          </Typography>
                          <Typography className="lineHeight">
                            {subItem.fontSize}/{subItem.lineHeight}
                          </Typography>
                        </CardContent>
                      </Card>
                    )
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <div className="buttons">
          <Button className="cancelButton" onClick={handleFontCancel}>
            Cancel
          </Button>
          <Button
            className="saveButton"
            onClick={handleFontSave}
            style={{ pointerEvents: value === "" ? "none" : "all" }}
          >
            Apply
          </Button>
        </div>
      </Grid>
    );
  });
};
export default observer(DefaultFonts);
