/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { Search } from "@mui/icons-material";
import { useObserver } from "mobx-react-lite";
import { ReactComponent as Youtube } from "../../assets/youtube-circle.svg";
import { ReactComponent as LobbyMessageIcon } from "../../assets/LobbyMessageIcon.svg";
import { ReactComponent as FirstAid } from "../../assets/first-aid.svg";
import { ReactComponent as MeetingSettingsIcon } from "../../assets/meetingSetting_icon.svg";
import { ReactComponent as SystemSettingsIcon } from "../../assets/systemSettings_icon.svg";
import { ReactComponent as Reminders } from "../../assets/reminders.svg";
import { ReactComponent as Messages } from "../../assets/appointment_msg.svg";
import { ReactComponent as DefaultTheme } from "../../assets/defaultTheme.svg";
import { ReactComponent as DefaultThemeActive } from "../../assets/defaultThemeActive.svg";
import { ReactComponent as CustomTheme } from "../../assets/customTheme.svg";
import { ReactComponent as CustomThemeActive } from "../../assets/customThemeActive.svg";
import { ReactComponent as DefaultFont } from "../../assets/defaultFont.svg";
import { ReactComponent as DefaultFontActive } from "../../assets/defaultFontActive.svg";
import { ReactComponent as CustomFontActive } from "../../assets/custom_font_active.svg";
import { ReactComponent as CustomFont } from "../../assets/customFont.svg";
import { IGetNotificationTemplate } from "../../state/StoreTypes";
import { apiService } from "../../services/ApiService";

import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";

interface IDashboardTabsProps {
  parentTabName: string;
  selectedSubTab: string;
  handleTabChange: (event: any, newValue: string) => void;
  searchText: string;
  setSearchText: (s: string) => void;
}

const DashboardTabs = (props: IDashboardTabsProps) => {
  const [notificationCount, setNotificationCount] = useState<
    IGetNotificationTemplate[]
  >([]);
  const pageName = "Dashboard";
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const globalStore = useGlobalStore();

  useEffect(() => {
    trackComponent();
    apiService
      .getNotification()
      .then((data: IGetNotificationTemplate[]) => {
        setNotificationCount(data);
      })
      .catch((error: any) => {
        AppInsightMethods.TrackAppInsightsException(
          `Error at fetch Notification Count API call, Component: ${pageName}, Error: ${error}`
        );
      });
  }, []);

  return useObserver(() => {
    return (
      <Grid className={"dashboard_nav"}>
        <Grid className={"dashboard_nav_tabs_container"}>
          {props.parentTabName === "Resources" && (
            <>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Materials" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Materials")}
              >
                <FirstAid className={"dashboard_tab_material_icon"} />
                <span>Materials</span>
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Relaxation Video" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Relaxation Video")}
              >
                <Youtube className={"dashboard_tab_Relaxation_icon"} />
                <span>Video</span>
              </Grid>
            </>
          )}
          {props.parentTabName === "Appointments" && (
            <>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Appointment Messages"
                    ? "active"
                    : ""
                }`}
                onClick={(e) =>
                  props.handleTabChange(e, "Appointment Messages")
                }
              >
                <Messages className={"dashboard_tab_material_icon"} />
                <span>Appointment Messages</span>
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Lobby Messages" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Lobby Messages")}
              >
                <LobbyMessageIcon className="noResult" />
                Lobby Messages
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Reminders" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Reminders")}
              >
                <Reminders className={"dashboard_tab_Relaxation_icon"} />
                <span>Reminders</span>
              </Grid>
            </>
          )}

          {props.parentTabName === "Notifications" && (
            <>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "All" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "All")}
              >
                <span>{`All ${
                  notificationCount.length > 0
                    ? `(${globalStore.notificationNumber})`
                    : ""
                }`}</span>
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Patient" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Patient")}
              >
                <span>Patient</span>
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Provider" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Provider")}
              >
                <span>Provider</span>
              </Grid>
            </>
          )}
          {props.parentTabName === "General" && (
            <>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "System Settings" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "System Settings")}
              >
                <SystemSettingsIcon
                  className={"dashboard_tab_systemSettings_icon"}
                />
                <span>System Settings</span>
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Meeting Settings" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Meeting Settings")}
              >
                <MeetingSettingsIcon className="dashboard_tab_meetingSettings_icon" />
                Meeting Settings
              </Grid>
            </>
          )}
          {props.parentTabName === "Test Accounts" && (
            <>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Test Users" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Test Users")}
              >
                <span>Test Users</span>
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Test Appointments" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Test Appointments")}
              >
                Test Appointments
              </Grid>
            </>
          )}
          {props.parentTabName === "Theme" && (
            <>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Default Theme" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Default Theme")}
              >
                {props.selectedSubTab === "Default Theme" ? (
                  <DefaultThemeActive
                    className={"dashboard_tab_defaultTheme_icon"}
                  />
                ) : (
                  <DefaultTheme className={"dashboard_tab_defaultTheme_icon"} />
                )}
                <span>System Theme</span>
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Custom Theme" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Custom Theme")}
              >
                {props.selectedSubTab === "Custom Theme" ? (
                  <CustomThemeActive
                    className={"dashboard_tab_defaultTheme_icon"}
                  />
                ) : (
                  <CustomTheme className={"dashboard_tab_defaultTheme_icon"} />
                )}
                Custom Theme
              </Grid>
            </>
          )}
          {props.parentTabName === "Fonts" && (
            <>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Default Fonts" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Default Fonts")}
              >
                {props.selectedSubTab === "Default Fonts" ? (
                  <DefaultFontActive
                    className={"dashboard_tab_defaultTheme_icon"}
                  />
                ) : (
                  <DefaultFont className={"dashboard_tab_defaultTheme_icon"} />
                )}
                <span>System Font</span>
              </Grid>
              <Grid
                className={`dashboard_tab ${
                  props.selectedSubTab === "Custom Fonts" ? "active" : ""
                }`}
                onClick={(e) => props.handleTabChange(e, "Custom Fonts")}
              >
                {props.selectedSubTab === "Custom Fonts" ? (
                  <CustomFontActive
                    className={"dashboard_tab_defaultTheme_icon"}
                  />
                ) : (
                  <CustomFont className={"dashboard_tab_defaultTheme_icon"} />
                )}
                Custom Font
              </Grid>
            </>
          )}
        </Grid>
        <Grid className={"dashboard_nav_right"}>
          {props.parentTabName !== "Notifications" &&
            props.parentTabName !== "Test Accounts" &&
            props.parentTabName !== "Theme" &&
            props.parentTabName !== "Fonts" && (
              <Grid className={"searchBox"}>
                <Search />
                <TextField
                  id="header-SearchBox"
                  placeholder={"Search"}
                  value={props.searchText}
                  onChange={(event) => {
                    props.setSearchText(event.target.value);
                  }}
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    );
  });
};

export default DashboardTabs;
