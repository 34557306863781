/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Backdrop,
  Tooltip,
  IconButton,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import { makeStyles } from "@mui/styles";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { helperMethods } from "../../hooks/helper";
import save from "../../assets/save.svg";
import cancel from "../../assets/cancel.png";
import upload from "../../assets/upload.png";
import { apiService } from "../../services/ApiService";
import MultipleFileUploadField from "../UploadFile/DragnDrop";
import { ReactComponent as Thumbnail } from "../../assets/ImageThumbnail.svg";
import { useGlobalStore } from "../../state/GlobalStore";

const useStyles = makeStyles({
  paperRoot: {
    top: "72%",
    width: "200px",
    height: "119px",
    position: "absolute",
    marginLeft: "29.5rem",
  },
  dialog: {
    width: "720px",
    height: "858px",
    maxWidth: "720px",
  },
  dialogURL: {
    width: "720px",
    height: "722px",
    maxWidth: "720px",
  },
  dropZone: {
    width: "95%",
    border: "dashed",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    marginLeft: "13px",
    marginTop: "16px",
    marginBottom: "13px",
    boxSizing: "border-box",
    minHeight: "184px",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
});

interface IVideoButton {
  callBackGetVideoList: () => void;
  popup: boolean;
  closeCallback: () => void;
}
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#ADADAD",
    fontStyle: "italic",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const AddVideo = (props: IVideoButton) => {
  const pageName = "VideoButton";
  const classes = useStyles();
  const helperAdd = helperMethods();
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [file, setFile] = useState("");
  const globalStore = useGlobalStore();
  /* eslint-enable no-param-reassign */
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadFiles, setUploadFile] = useState<File>();
  const [video, setVideo] = useState({
    title: "",
    description: "",
    url: "",
    fileName: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [fileName, setFileName] = useState("No image uploaded");
  const [showTitleErrorMgs, setShowtitleErrorMgs] = useState("");
  const [showUrlErrorMgs, setShowUrlErrorMgs] = useState("");

  const [setDescriptionErrorMgs, setShowDescriptionErrorMgs] = useState("");
  const [showFileErrorMgs, setShowFileErrorMgs] = useState("");
  const [showTitleErrorMessage, setShowTitleErrormsg] = useState(false);
  const [fileSizeexceed, setFileSizeExceed] = useState("");
  const [fileUploadSizeExceed, setFileUploadSizeExceed] = useState("");
  const [selectedSubTab, setSelectedSubTab] = useState("Upload Video");
  const [urlVal, setUrlVal] = useState<string>("");
  const [thumbnailFileExtensionError, setThumbnailFileExtensionError] =
    useState("");

  useEffect(() => {
    trackComponent();
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightsConstants.Information,
      `${pageName} Page Load`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
    // eslint-disable-next-line
  }, []);
  /* Handle Thumbnail File */
  const handleChange = (e) => {
    try {
      let url = URL.createObjectURL(e.target.files[0]);
      setFile(url);
      url = "";
      setSelectedFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Reset Field */
  const updatePhotoField = () => {
    try {
      setFile("");
      setFileName("No image uploaded");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updatePhotoField, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const handleInputs = (e) => {
    try {
      const newdata = { ...video };
      newdata[e.target.id] = e.target.value;
      setVideo(newdata);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleInputs, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const updateField = () => {
    try {
      setVideo({ ...video, title: " ", description: " ", url: " " });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at updateField, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* File Upload calling API */
  const onFileUpload = async () => {
    try {
      if (selectedSubTab !== "URL") {
        let videoData = false;
        videoData = validate();

        if (videoData) {
          setShowTitleErrormsg(true);
          return;
        }
        let fileSizeValidation = false;
        fileSizeValidation = fileSizeValidate();
        if (fileSizeValidation) {
          return;
        }
        let fileUploadSizeValidation = false;
        fileUploadSizeValidation = fileUploadSizeValidate();
        if (fileUploadSizeValidation) {
          return;
        }

        if (uploadFiles) {
          const formData = new FormData();
          formData.append("title", video.title);
          formData.append("description", video.description);
          formData.append(
            "uploadedBy",
            "publictester3@acp200021a.onmicrosoft.com"
          );
          if (selectedFile) {
            formData.append("thumbnailFile", selectedFile, selectedFile.name);
          }
          formData.append("uploadFile", uploadFiles, uploadFiles.name);
          formData.append("inputUrl", urlVal);
          setShowSpinner(true);

          if (
            video.title !== "" ||
            video.description !== "" ||
            uploadFiles.name !== ""
          ) {
            apiService
              .newVideo(formData)
              .then((res: any) => {
                if (res === "success") {
                  helperAdd.setSuccessMessageEvent("Video added successfully!");
                }
                props.closeCallback();
                setShowSpinner(false);
                updateField();
                updatePhotoField();
                globalStore.setOpenedModal("none");
                helperAdd.setSuccessMessageEvent("Video added successfully!");
                props.callBackGetVideoList();
              })
              .catch((error: any) => {
                setShowSpinner(false);
                AppInsightMethods.TrackAppInsightsException(
                  `Error at addingNew Video API call, Component: ${pageName} Error : ${error}`
                );
              });
          }
        }
      } else {
        let videoData = false;
        videoData = validate();

        if (videoData) {
          setShowTitleErrormsg(true);
          return;
        }

        const formData = new FormData();
        formData.append("title", video.title);
        formData.append("description", video.description);
        formData.append(
          "uploadedBy",
          "publictester3@acp200021a.onmicrosoft.com"
        );

        formData.append("thumbnailFile", "");
        formData.append("uploadFile", "");

        formData.append("inputUrl", urlVal);
        setShowSpinner(true);

        if (video.title !== "" || video.description !== "" || urlVal !== "") {
          apiService
            .newVideo(formData)
            .then((res: any) => {
              if (res === "success") {
                helperAdd.setSuccessMessageEvent("Video added successfully!");
              }
              props.closeCallback();
              setShowSpinner(false);
              updateField();
              updatePhotoField();
              globalStore.setOpenedModal("none");
              helperAdd.setSuccessMessageEvent("Video added successfully!");
              props.callBackGetVideoList();
            })
            .catch((error: any) => {
              setShowSpinner(false);
              AppInsightMethods.TrackAppInsightsException(
                `Error at addingNew Video API call, Component: ${pageName} Error : ${error}`
              );
            });
        }
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at AddVideo API, Component: ${pageName} Error : ${error}`
      );
    }
  };

  const validate = () => {
    try {
      let validationFailed = false;

      if (video.title === "") {
        validationFailed = true;
        setShowtitleErrorMgs("Please enter title");
      } else {
        setShowtitleErrorMgs("");
      }
      if (video.description === "") {
        validationFailed = true;
        setShowDescriptionErrorMgs("Please add Description");
      } else {
        setShowDescriptionErrorMgs("");
      }

      if (selectedSubTab !== "URL") {
        if (
          typeof uploadFiles?.name === "undefined" ||
          uploadFiles.name === ""
        ) {
          validationFailed = true;
          setShowFileErrorMgs("Please upload file");
        } else {
          setShowFileErrorMgs("");
        }
      }

      if (selectedSubTab === "URL") {
        if (urlVal === "") {
          validationFailed = true;
          setShowUrlErrorMgs("Enter video source URL or YouTube link");
        } else {
          setShowUrlErrorMgs("");
        }
      }

      return validationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at AddVideo API, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  /* onBlur handler for title field */
  const onBlurTitle = () => {
    try {
      if (video.title === "") {
        setShowtitleErrorMgs("Please enter Title");
      } else {
        setShowtitleErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurTitle, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* onBlur handler for description field */
  const onBlurDescription = () => {
    try {
      if (video.description === "") {
        setShowDescriptionErrorMgs("Please add Description");
      } else {
        setShowDescriptionErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurDescription, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onBlur handler for link field */
  const onBlurUrl = () => {
    try {
      if (urlVal === "") {
        setShowUrlErrorMgs("Enter video source URL or YouTube link");
      } else {
        setShowUrlErrorMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBlurUrl, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Cancel button handler */
  const onCancelClickEventHandler = () => {
    try {
      setVideo({
        title: "",
        description: "",
        url: "",
        fileName: "",
      });
      setFile("");
      setFileName("No image uploaded");
      setShowtitleErrorMgs("");
      setShowUrlErrorMgs("");
      setShowDescriptionErrorMgs("");
      setShowFileErrorMgs("");
      setShowTitleErrormsg(false);
      setFileSizeExceed("");
      setFileUploadSizeExceed("");

      globalStore.setOpenedModal("none");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClickEventHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Thumbnail File Validation */
  const fileSizeValidate = () => {
    try {
      let filesizevalidationFailed = false;
      const maxSize = 2;
      if (selectedFile) {
        const fsize = selectedFile.size / (1024 * 1024);
        if (fsize > maxSize) {
          filesizevalidationFailed = true;
          setFileSizeExceed("Image size exceed 2 MB");
        } else {
          setFileSizeExceed("");
        }
        if (
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "jpg" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "jpeg" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "png" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "gif" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "JPG" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "JPEG" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "PNG" &&
          selectedFile?.name.split(".").pop()?.toLowerCase() !== "GIF"
        ) {
          filesizevalidationFailed = true;
          setThumbnailFileExtensionError("File Format not supported");
        } else {
          setThumbnailFileExtensionError("");
        }
      }
      return filesizevalidationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fileSizeValidate, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };
  /* Upload File Validation */
  const fileUploadSizeValidate = () => {
    try {
      let filesizevalidationFailed = false;
      const maxSize = 200;
      if (uploadFiles) {
        const fsize = uploadFiles.size / (1024 * 1024);
        if (fsize > maxSize) {
          filesizevalidationFailed = true;
          setFileUploadSizeExceed("File size should not be more than 200MB");
        } else {
          setFileUploadSizeExceed("");
        }
      }
      return filesizevalidationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at fileUploadSizeValidate, Component: ${pageName} Error : ${error}`
      );
      return false;
    }
  };

  const handleTabChange = (e, val) => {
    setSelectedSubTab(val);
  };

  return useObserver(() => {
    return (
      <Dialog
        open={props.popup}
        onClose={() => {
          globalStore.setOpenedModal("none");
          setUrlVal("");
          setFileName("");
          setFile("");
        }}
        classes={{
          paper:
            selectedSubTab === "Upload Video"
              ? classes.dialog
              : classes.dialogURL,
        }}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <b style={{ marginRight: "33rem" }}>Add Video</b>
          <IconButton
            onClick={() => {
              onCancelClickEventHandler();
              props.closeCallback();
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid className={"video-text"}>
            Title
            <span className={"video-title"}>*</span>
          </Grid>
          <Grid className={"videoText-box"}>
            <TextField
              fullWidth
              variant="outlined"
              value={video.title}
              onChange={handleInputs}
              placeholder="Video Title"
              required
              id="title"
              error={!!showTitleErrorMgs}
              onBlur={() => onBlurTitle()}
            />
          </Grid>
          <div className={"video-error"}>{showTitleErrorMgs || ""}</div>
          <Grid className={"video-text"}>
            Description<span className={"video-title"}>*</span>
          </Grid>
          <Grid className={"videoText-box"}>
            <TextField
              fullWidth
              id="description"
              required
              variant="outlined"
              onChange={handleInputs}
              value={video.description}
              placeholder="Type Video Description"
              multiline
              rows={4}
              error={!!setDescriptionErrorMgs}
              onBlur={() => onBlurDescription()}
            />
          </Grid>
          <div className={"video-error"}>{setDescriptionErrorMgs || ""}</div>
          <Grid className={"editSummary_nav_tabs_container"}>
            <>
              <Grid
                className={`editSummary_tab ${
                  selectedSubTab === "Upload Video" ? "active" : ""
                }`}
                onClick={(e) => {
                  handleTabChange(e, "Upload Video");
                  setUrlVal("");
                }}
              >
                <span>Upload Video</span>
              </Grid>
              <Grid
                className={`editSummary_tab ${
                  selectedSubTab === "URL" ? "active" : ""
                }`}
                onClick={(e) => {
                  handleTabChange(e, "URL");
                  setFileName("");
                  setFile("");
                }}
              >
                <span>URL</span>
              </Grid>
            </>
          </Grid>

          {selectedSubTab === "Upload Video" ? (
            <>
              <Grid
                className={
                  showTitleErrorMessage ? "videoRedBox" : "videoText-box"
                }
              >
                <MultipleFileUploadField
                  handleFileChange={(fileUp: File) => {
                    setUploadFile(fileUp);
                    if (fileUp && fileUp.name !== "") {
                      setShowFileErrorMgs("");
                      setShowTitleErrormsg(false);
                    }
                  }}
                  type="video"
                />
              </Grid>
              <div className={"video-error"}>{showFileErrorMgs || ""}</div>
              <div className={"video-error"}>{fileUploadSizeExceed || ""}</div>
              <Grid className={"video-text"}>
                Upload thumbnail image&nbsp;
                <LightTooltip title="If the reference image is not uploaded default image will appear in lobby for this video.">
                  <IconButton>
                    <InfoOutlinedIcon
                      style={{ width: "15px", height: "15px" }}
                    />
                  </IconButton>
                </LightTooltip>
              </Grid>
              <em style={{ fontSize: "12px", color: "#ADADAD" }}>
                (Upload reference image for the material)
              </em>
              <div>
                <label htmlFor="files" className="uploadbtn">
                  <span style={{ marginLeft: "12px" }}>
                    <img
                      src={upload}
                      alt="upload"
                      className="uploadArrow"
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    />
                    Image
                  </span>
                  <span
                    className={selectedFile ? "fileName" : "defaultfileName"}
                  >
                    {fileName}
                  </span>
                  <input
                    id="files"
                    hidden
                    name="upload-photo"
                    type="file"
                    accept="image/*"
                    onChange={handleChange}
                  />
                </label>
              </div>

              <Grid className="backgroundBox">
                <Thumbnail />
                <div className="fileInfo">200px X 120px</div>
              </Grid>
              <div className="uploadContentMgs">
                Supports : PNG, JPEG | Max Size : 2 MB&emsp;
                {file.length > 0 && (
                  <span className={"video-errorFileSize"}>
                    {fileSizeexceed || ""}
                    <div className={"video-error"}>
                      {thumbnailFileExtensionError || ""}
                    </div>
                  </span>
                )}
              </div>
              {file.length > 0 && (
                <Card className={classes.paperRoot}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="img"
                      height="140"
                      image={file}
                      title="Contemplative Reptile"
                    />
                  </CardActionArea>
                </Card>
              )}
            </>
          ) : (
            <>
              {" "}
              <Grid className="videoText-box">
                <p className="thumbNailText">
                  Paste YouTube link or other video source URL
                </p>
                {/* {!uploads && ( */}
                <Grid className="videoURL">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter video source URL or YouTube link"
                    required
                    id="title"
                    value={urlVal}
                    error={!!showUrlErrorMgs}
                    onBlur={() => onBlurUrl()}
                    onChange={(e) => {
                      setUrlVal(e.target.value);
                    }}
                  />
                </Grid>
                <div className={"video-error"}>{showUrlErrorMgs || ""}</div>
              </Grid>
            </>
          )}
        </DialogContent>

        <DialogActions style={{ paddingBottom: 16 }}>
          <Button
            onClick={() => {
              onCancelClickEventHandler();
              props.closeCallback();
              setUrlVal("");
              setFileName("");
              setFile("");
            }}
          >
            <img
              src={cancel}
              alt="cancel"
              className="logo pointer cursor"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={0}
            />
          </Button>
          <Button onClick={onFileUpload}>
            <img
              src={save}
              alt="add"
              className="logo pointer cursor"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={0}
            />
          </Button>
        </DialogActions>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Dialog>
    );
  });
};

export default AddVideo;
