/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightsConstants } from "../../Constants";
import { useGlobalStore } from "../../state/GlobalStore";
import HeaderLogo from "../../assets/oltiva_logo.svg";
import Profile from "../../assets/profile_pic.png";
import NotificationIcon from "../../assets/notification_icon.svg";
import UploadIcon from "../../assets/upload.svg";
import ImageIcon from "../../assets/image_icon.svg";
import { apiService } from "../../services/ApiService";
import { helperMethods } from "../../hooks/helper";
import { ReactComponent as Thumbnail } from "../../assets/ImageThumbnail.svg";

export default function ExtendedLayout() {
  const globalStore = useGlobalStore();
  const pageName = "MinimalLayout";
  const appInsights = useAppInsightsContext();
  const [isPreview, setIsPreview] = useState(false);
  const [tagLine, setTagLine] = useState(globalStore.tagLine);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [fileUpload, setfileUpload] = useState<any>("");
  const hiddenFileInput = React.useRef<any>();
  const [showSpinner, setShowSpinner] = useState(false);
  const [isValid, setIsValid] = useState<any>("");
  const helperAdd = helperMethods();
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [validationFailed, setValidationFailed] = useState<any>(false);
  const [imgFile, setImgFile] = useState<any>("");
  const [imgFile0, setImgFile0] = useState<any>("");
  useEffect(() => {
    trackComponent();
  }, []);

  const handleClick = (event) => {
    hiddenFileInput?.current?.click();
  };
  const handleChange = (event) => {
    setValidationFailed(false);
    const file = event.target.files[0];
    setImgFile(event.target.files);
    setImgFile0(event.target.files[0]);
    const maxSize = 2000;
    const fileSize = file?.size / 1024;
    if (
      file?.name.split(".").pop() !== "jpg" &&
      file?.name.split(".").pop() !== "jpeg" &&
      file?.name.split(".").pop() !== "png" &&
      file?.name.split(".").pop() !== "gif" &&
      file?.name.split(".").pop() !== "JPG" &&
      file?.name.split(".").pop() !== "JPEG" &&
      file?.name.split(".").pop() !== "PNG"
    ) {
      // setIsValid("invalid");
      setValidationFailed(true);
      setErrorMessage("File Format not supported");
    }
    if (fileSize > maxSize) {
      // setIsValid("invalid");
      setValidationFailed(true);
      setErrorMessage("File size should not be more than 2MB");
    }
  };

  useEffect(() => {
    !validationFailed && imgFile.length !== 0
      ? (setfileUpload(imgFile0),
        globalStore.setExtendedBackgroundImage(URL?.createObjectURL(imgFile0)),
        globalStore.setImageName(imgFile0.name))
      : (setfileUpload(""),
        globalStore.setExtendedBackgroundImage(""),
        globalStore.setImageName("No Files Chosen"));
  }, [imgFile0]);

  const cancelLayout = () => {
    globalStore.setLayoutType(globalStore.customLayoutData.layoutStyle);
    globalStore.setExtendedBackgroundImage(globalStore.customLayoutData.url);
    globalStore.setTagLine(globalStore.customLayoutData.appTagLine);
  };

  const applyLayout = () => {
    globalStore.setIsCustomized(false);
    const formData = new FormData();
    tagLine !== "" && fileUpload !== "" && !validationFailed
      ? (setIsValid("valid"),
        formData.append("layoutStyle", globalStore.layoutType),
        formData.append("appTagLine", tagLine),
        formData.append("backgroundImage", fileUpload, fileUpload.name),
        formData.append(
          "uploadedBy",
          "publictester3@acp200021a.onmicrosoft.com"
        ),
        setShowSpinner(true),
        apiService
          .addCustomLayout(formData)
          .then((res: any) => {
            if (res === "success") {
              // helperAdd.setSuccessMessageEvent("Successfully Updated");
              setShowSpinner(false);
              globalStore.setIsCustomized(true);
            }
          })
          .catch((error: any) => {
            globalStore.setIsCustomized(false);
            setShowSpinner(false);
            AppInsightMethods.TrackAppInsightsException(
              `Error at add layout API API call, Component: ${pageName}, Error: ${error}`
            );
          }))
      : (setIsValid("invalid"), globalStore.setIsCustomized(false));
  };

  return useObserver(() => {
    return (
      <>
        <div className="tagContainer">
          <p className="tagLine">Application Tag Line</p>
          <TextField
            error={isValid === "invalid" && tagLine === ""}
            helperText={
              isValid === "invalid" && tagLine === "" ? "Enter TagLine" : ""
            }
            className="textBox"
            value={tagLine}
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => {
              setTagLine(e.target.value);
            }}
          />
        </div>
        <div className="imageContainer">
          <p className="uploadText">Upload Layout Background Image</p>
          <p className="uploadDesc">
            (Upload Image for Extended layout background)
          </p>
          <div style={{ display: "flex", marginBottom: "40px" }}>
            <div
              className="fileBox"
              style={
                (isValid === "invalid" && fileUpload === "") || validationFailed
                  ? { borderColor: "red" }
                  : {}
              }
            >
              <Button className="labelContainer" onClick={handleClick}>
                <img src={UploadIcon} alt="" />
                Image
              </Button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              {globalStore.imageName !== "No Files Chosen" &&
              !validationFailed ? (
                <>
                  <img className="imageIcon" src={ImageIcon} alt="" />
                  <p className="imgName">{globalStore.imageName}</p>
                  <div
                    className="buttonCircle"
                    onClick={() => {
                      globalStore.setExtendedBackgroundImage(undefined);
                      globalStore.setImageName("No Files Chosen");
                    }}
                  >
                    <div
                      className="icon"
                      onClick={() => {
                        globalStore.setExtendedBackgroundImage(undefined);
                        globalStore.setImageName("No Files Chosen");
                        setfileUpload("");
                      }}
                    >
                      +
                    </div>
                  </div>
                </>
              ) : (
                <p className="imgName">No Files Chosen</p>
              )}
            </div>
            <p className="uploadvalidation">
              Supports : PNG, JPEG | Max Size : 2 mb (Recommended more than
              1500x80 Px)
            </p>
            {globalStore.extendedBackgroundImage !== "" &&
            globalStore.extendedBackgroundImage !== undefined &&
            !validationFailed ? (
              <img
                className="displayImage"
                src={globalStore.extendedBackgroundImage}
                alt=""
              />
            ) : (
              <div className="displayNoImage">
                <Thumbnail />
              </div>
            )}
          </div>
          {isValid === "invalid" && fileUpload === "" && !validationFailed && (
            <p className="fileErrorText">Upload File</p>
          )}
          {validationFailed && <p className="fileErrorText">{errorMessage}</p>}
        </div>
        <Grid
          className="previewText"
          style={isPreview ? { color: "#8A8A8A" } : { color: "#1447D4" }}
          onClick={() => {
            setIsPreview(true);
          }}
        >
          Preview
        </Grid>
        {isPreview && (
          <div className="extendedlayoutContainer" style={{ height: "120px" }}>
            <div
              className="headerTop"
              style={{
                alignSelf: "center",
                backgroundImage: `linear-gradient(to bottom, transparent -40%, black 180%), url(${globalStore.extendedBackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "1480px",
              }}
            >
              <img src={HeaderLogo} alt="logo" className="logo" />
              <p className="headerName">OLTIVA HEALTHCARE</p>
            </div>
            <p className="tagLineText">{tagLine}</p>
          </div>
        )}
        <div
          className="buttons"
          style={isPreview ? { marginTop: "32px" } : { marginTop: "170px" }}
        >
          <Button className="cancelButton" onClick={cancelLayout}>
            Cancel
          </Button>
          <Button className="saveButton" onClick={applyLayout}>
            Save
          </Button>
        </div>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
